import crypto from "../shared/aion_crypto";
import apiClient from "./apiClient";

const defaultState = () => {
    return {
        error: null,
        privateKey: null,
        publicKey: null,
        signature: null,
        voterSessionUuid: null,
        answered: null,
        voter: {
            name: null,
            participating: null
        }
    }
}
const signUpSession = {
    namespaced: true,
    state: defaultState(),
    mutations: {
        startSession(state, { privateKey, voter }){
            state.privateKey = privateKey
            state.publicKey = crypto.generateKeyPair(privateKey).public_key
            state.signature = crypto.generateSchnorrSignature('', privateKey)
            state.voter = voter
        },
        destroySession(state){
            state.privateKey = null
            state.publicKey = null
            state.answered = null
            state.voter = {
                name: null,
                participating: null
            }
        },
        setAnswered(state, answered){
            state.answered = answered
        },
        setVoter(state, voter){
            state.voter = voter
        },
        setError(state, error){
            state.error = error
        }
    },
    actions: {
        authenticate({state, commit, rootState, dispatch}, electionCodes){
            let privateKey = electionCodes.map(code => crypto.electionCodeToPrivateKey(code, "pbkdf2")).reduce(crypto.addBigNums)
            let signature = crypto.generateSchnorrSignature('', privateKey)
            let publicKey = crypto.generateKeyPair(privateKey).public_key

            return apiClient
                .post(`${rootState.electionUrl}/authenticate_sign_up`, {
                    public_key: publicKey,
                    signature: signature
                })
                .then(res => {
                    let { voter: voter } = res.data
                    if( voter ){
                        commit('startSession', { privateKey, voter })
                        commit('setAnswered', voter.participating !== null)
                    }
                }).catch(error =>{
                    if(error.response) {
                        commit('setError', error.response.data.error)
                    }
                })
        },
        submitAnswer({state, commit, rootState}, participation) {
            apiClient.put(rootState.electionUrl+"/submit_sign_up", {
                signature: state.signature,
                public_key: state.publicKey,
                participating: participation
            }).then(res => {
                let { voter: voter } = res.data
                if( voter ){
                    commit('setVoter', voter)
                    commit('setAnswered', true)
                }
            }).catch(error =>{
                commit('destroySession')
                if(error.response) {
                    commit('setError', error.response.data.error)
                }
            })
        },
        destroySession({commit}){
            commit('destroySession')
        }
    }
}

export default signUpSession;